body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --wp-controls-border-color: #000;
  --wp-controls-background-color: #fff;
  --wp-mute-solo-color: #343a40;
  --wp-mute-solo-hover-color: #fff;
  --wp-mute-solo-focus-color: rgba(52, 58, 64, 0.5);
  --wp-btn-danger-background-hover-color: #c82333;
  --wp-btn-danger-border-hover-color: #bd2130;
  --wp-btn-danger-background-color: #dc3545;
  --wp-btn-danger-border-color: #dc3545;
  --wp-btn-danger-focus-color: rgba(225, 83, 97, 0.5);
  --wp-btn-danger-color: #fff;
  --wp-btn-info-background-hover-color: #138496;
  --wp-btn-info-border-hover-color: #117a8b;
  --wp-btn-info-background-color: #17a2b8;
  --wp-btn-info-border-color: #17a2b8;
  --wp-btn-info-focus-color: rgba(58, 176, 195, 0.5);
  --wp-btn-info-color: #fff;
  --wp-fade-background-color: rgba(0, 0, 0, 0.1);
  --wp-channel-color: #999;
/*  --wp-channel-progress-color: #fbbc04;*/
  --wp-channel-progress-color: #555555;
  --wp-cursor-color: rgba(0, 0, 0, 0.1);
  --wp-selection-background-color: rgba(0, 0, 0, 0.1);
  --wp-selection-point-color: red;
  --wp-tracks-container-background-color: transparent;
  --wp-range-slider-label-color: black;
  --wp-range-slider-color: goldenrod;
  --wp-range-slider-focus-color: black;
  --wp-range-slider-background-color: #ddd;
  --wp-range-slider-background-focus-color: #bbb;
  --wp-annotation-box-border-color: grey;
  --wp-annotation-current-background-color: #ebf4f6;
  --wp-annotation-icon-hover-color: orange; }

.playlist {
  margin: 0; }
  .playlist *, .playlist ::after, .playlist ::before {
    box-sizing: border-box; }
  .playlist .btn:not(:disabled) {
    cursor: pointer; }
  .playlist .btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out; }
  .playlist .btn-outline-dark {
    color: #343a40;
    color: var(--wp-mute-solo-color);
    border-color: #343a40;
    border-color: var(--wp-mute-solo-color); }
  .playlist .btn-outline-dark:hover {
    color: #fff;
    color: var(--wp-mute-solo-hover-color);
    background-color: #343a40;
    background-color: var(--wp-mute-solo-color);
    border-color: #343a40;
    border-color: var(--wp-mute-solo-color); }
  .playlist .btn-outline-dark:active:focus, .playlist .btn-outline-dark:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
    box-shadow: 0 0 0 0.2rem var(--wp-mute-solo-focus-color); }
  .playlist .btn-danger {
    color: #fff;
    color: var(--wp-btn-danger-color);
    background-color: #dc3545;
    background-color: var(--wp-btn-danger-background-color);
    border-color: #dc3545;
    border-color: var(--wp-btn-danger-border-color); }
  .playlist .btn-danger:hover {
    color: #fff;
    color: var(--wp-btn-danger-color);
    background-color: #c82333;
    background-color: var(--wp-btn-danger-background-hover-color);
    border-color: #bd2130;
    border-color: var(--wp-btn-danger-border-hover-color); }
  .playlist .btn-danger:active:focus, .playlist .btn-danger:focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
    box-shadow: 0 0 0 0.2rem var(--wp-btn-danger-focus-color); }
  .playlist .btn-info {
    color: #fff;
    color: var(--wp-btn-info-color);
    background-color: #17a2b8;
    background-color: var(--wp-btn-info-background-color);
    border-color: #17a2b8;
    border-color: var(--wp-btn-info-border-color); }
  .playlist .btn-info:hover {
    color: #fff;
    color: var(--wp-btn-info-color);
    background-color: #138496;
    background-color: var(--wp-btn-info-background-hover-color);
    border-color: #117a8b;
    border-color: var(--wp-btn-info-border-hover-color); }
  .playlist .btn-info:active:focus, .playlist .btn-info:focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
    box-shadow: 0 0 0 0.2rem var(--wp-btn-info-focus-color); }
  .playlist .btn-xs {
    padding: .25rem .4rem;
    font-size: .875rem;
    line-height: .5;
    border-radius: .2rem; }
  .playlist .btn-group {
    margin-bottom: 0.3rem; }
  .playlist .btn-group > .btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .playlist .btn-group > .btn:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .playlist .playlist-time-scale {
    height: 30px;   margin:0 8px !important;}
  .playlist .playlist-tracks {
    background: transparent;
    background: var(--wp-tracks-container-background-color);
     }
  .playlist .channel {
    background: #999;
    background: var(--wp-channel-color); 
/*      -moz-box-shadow:    0px 0px 2px 0px #000;
  -webkit-box-shadow: 0px 0px 2px 0px #000;
  box-shadow:         0px 0px 2px 0px #000;*/
/*  border:  1px solid #555555;*/

  }

  .playlist .channel canvas {
/*      -moz-box-shadow:    0px 0px 2px 0px #000;
  -webkit-box-shadow: 0px 0px 2px 0px #000;
  box-shadow:         0px 0px 2px 0px #000;*/
/*  border:  1px solid #555555;*/
  }
  .playlist .channel-progress {
    background: #555555;
    background: var(--wp-channel-progress-color); }
  .playlist .cursor {
    background: rgba(0, 0, 0, 0.1);
    background: var(--wp-cursor-color); }
  .playlist .wp-fade {
    background-color: rgba(0, 0, 0, 0.1);
    background-color: var(--wp-fade-background-color); }
  .playlist .state-cursor,
  .playlist .state-select {
    cursor: pointer; }
  .playlist .state-fadein {
    cursor: w-resize; }
  .playlist .state-fadeout {
    cursor: e-resize; }
  .playlist .state-shift {
    cursor: ew-resize;
   /*   cursor: pointer;*/ }
  .playlist .selection.point {
/*    background: var(--wp-selection-point-color); */}
  .playlist .selection.segment {
    background: rgba(0, 0, 0, 0.1);
    background: var(--wp-selection-background-color); }
  .playlist .channel-wrapper.silent .channel {
    opacity: 0.3; }
    .playlist .channel-wrapper{
        margin:4px !important;

    }
  .playlist .controls {
    background: #fff;
    background: var(--wp-controls-background-color);
    text-align: center;
    border: 1px solid #000;
    border: 1px solid var(--wp-controls-border-color);
    border-radius: 0.2rem; }
    .playlist .controls .track-header {
      overflow: hidden;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0.2rem;
      font-size: 0.75rem; }
      .playlist .controls .track-header button {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center; }
    .playlist .controls label {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
      padding: 0 1rem;
      margin-bottom: 0.2rem; }
    .playlist .controls label:before {
      color: black;
      color: var(--wp-range-slider-label-color);
      font-size: 18px;
      padding-right: 5px;
      -moz-osx-font-smoothing: grayscale; }
    .playlist .controls label:after {
      color: black;
      color: var(--wp-range-slider-label-color);
      font-size: 18px;
      padding-left: 5px; }
    .playlist .controls label.volume:before {
      content: "\F027"; }
    .playlist .controls label.volume:after {
      content: "\F028"; }
    .playlist .controls label.stereopan:before {
      content: "L"; }
    .playlist .controls label.stereopan:after {
      content: "R"; }
    .playlist .controls input[type="range"] {
      -webkit-appearance: none;
      appearance: none;
      display: inline-block;
      width: 75%; }
    .playlist .controls input[type="range"]::-webkit-slider-runnable-track {
      height: 5px;
      background: #ddd;
      background: var(--wp-range-slider-background-color);
      border: none;
      border-radius: 3px; }
    .playlist .controls input[type="range"]::-moz-range-track {
      height: 5px;
      background: #ddd;
      background: var(--wp-range-slider-background-color);
      border: none;
      border-radius: 3px; }
    .playlist .controls input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      border: none;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background: goldenrod;
      background: var(--wp-range-slider-color);
      margin-top: -5px;
      cursor: ew-resize; }
    .playlist .controls input[type="range"]::-moz-range-thumb {
      -webkit-appearance: none;
      appearance: none;
      border: none;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background: goldenrod;
      background: var(--wp-range-slider-color);
      margin-top: -5px;
      cursor: ew-resize; }
    .playlist .controls input[type="range"]:focus {
      outline: none; }
    .playlist .controls input[type="range"]:focus::-webkit-slider-runnable-track {
      background: #bbb;
      background: var(--wp-range-slider-background-focus-color); }
    .playlist .controls input[type="range"]:focus::-moz-range-track {
      background: #bbb;
      background: var(--wp-range-slider-background-focus-color); }
    .playlist .controls input[type="range"]:focus::-webkit-slider-thumb {
      border: 2px solid black;
      border: 2px solid var(--wp-range-slider-focus-color); }
    .playlist .controls input[type="range"]:focus::-moz-range-thumb {
      border: 2px solid black;
      border: 2px solid var(--wp-range-slider-focus-color); }
  .playlist .annotations .annotations-boxes {
    text-align: center; }
  .playlist .annotations .annotation-box {
    border: 2px dashed grey;
    border: 2px dashed var(--wp-annotation-box-border-color);
    padding: 0 10px;
    line-height: 1.5; }
    .playlist .annotations .annotation-box .resize-handle {
      background: grey;
      background: var(--wp-annotation-box-border-color);
      opacity: 0.3;
      cursor: ew-resize; }
    .playlist .annotations .annotation-box .id {
      cursor: pointer;
      display: inline-block;
      width: 100%;
      height: 100%; }
  .playlist .annotations .annotations-text {
    font-size: 19px;
    font-weight: 300;
    margin-top: 1em;
    height: 160px;
    overflow-x: hidden;
    overflow-y: auto; }
    .playlist .annotations .annotations-text .annotation {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: stretch; }
      .playlist .annotations .annotations-text .annotation span {
        margin: 0.3rem 0.6rem; }
        .playlist .annotations .annotations-text .annotation span:last-of-type {
          margin-right: 1.2rem; }
      .playlist .annotations .annotations-text .annotation .annotation-id {
        font-size: 16px;
        line-height: 27px; }
      .playlist .annotations .annotations-text .annotation .annotation-start {
        font-size: 16px;
        line-height: 27px; }
      .playlist .annotations .annotations-text .annotation .annotation-end {
        font-size: 16px;
        line-height: 27px; }
      .playlist .annotations .annotations-text .annotation .annotation-lines {
        flex-grow: 10; }
      .playlist .annotations .annotations-text .annotation .annotation-actions {
        flex-basis: auto;
        width: 85px;
        text-align: right;
        font-size: 16px; }
        .playlist .annotations .annotations-text .annotation .annotation-actions i {
          margin-right: 0.6rem; }
          .playlist .annotations .annotations-text .annotation .annotation-actions i:last-of-type {
            margin-right: 0; }
          .playlist .annotations .annotations-text .annotation .annotation-actions i:hover {
            color: orange;
            color: var(--wp-annotation-icon-hover-color);
            cursor: pointer; }
  .playlist .annotations .current {
    background-color: #ebf4f6;
    background-color: var(--wp-annotation-current-background-color); }

